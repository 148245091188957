import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import HomePage from './components/HomePage';
import AccountPage from './components/AccountPage';
import EventsPage from './components/EventsPage';
import ProfilePage from './components/ProfilePage';
import SettingsPage from './components/SettingsPage';
import AdminPage from './components/AdminPage';
import BingoPage from './components/BingoPage';
import { AuthProvider } from './AuthContext';
import './App.css';
import headerImage from './assets/EaglesHeader.png';

document.title = "Ypsilanti Eagles #2250";

function App() {
  return (
    <AuthProvider>
      <Router>
        <div className="headerImageContainer" style={{ backgroundImage: `url(${headerImage})` }}></div>
        <nav>
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/events">Events</Link></li>
            <li className="account-link"><Link to="/account">Account</Link></li>
            <li><Link to="/admin">Admin</Link></li>
            <li><Link to="/bingo">Bingo</Link></li>
          </ul>
        </nav>

        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/account" element={<AccountPage />} />
          <Route path="/events" element={<EventsPage />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/settings" element={<SettingsPage />} />
          <Route path="/admin" element={<AdminPage />} />
          <Route path="/bingo" element={<BingoPage />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
