// src/components/BingoBoard.js
import React, { useEffect, useState } from 'react';
import { ref, onValue } from 'firebase/database';
import { database } from '../firebase';
import './BingoBoard.css';

const BingoBoard = () => {
    const [calledCards, setCalledCards] = useState([]);

    useEffect(() => {
        const calledCardsRef = ref(database, 'calledCards');
        onValue(calledCardsRef, (snapshot) => {
            const data = snapshot.val();
            setCalledCards(data || []);
        });
    }, []);

    return (
        <div>
            <h3>Called Cards:</h3>
            <div className="called-cards">
                {calledCards.map((card, index) => (
                    <img
                        key={index}
                        src={card.image}
                        alt={`${card.value} of ${card.suit}`}
                        className="called-card" // Apply the CSS class
                    />
                ))}
            </div>
        </div>
    );
};

export default BingoBoard;
