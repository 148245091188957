// src/components/BingoCaller.js
import React, { useState, useEffect } from 'react';
import { ref, set } from 'firebase/database';
import { database } from '../firebase';
import { createDeck, shuffleDeck } from '../deck';
import './BingoCaller.css';

const BingoCaller = () => {
    const [deck, setDeck] = useState([]);
    const [calledCards, setCalledCards] = useState([]);
    const [newCard, setNewCard] = useState(null);

    useEffect(() => {
        const initialDeck = shuffleDeck(createDeck());
        setDeck(initialDeck);
    }, []);

    const callCard = () => {
        if (deck.length === 0) return;
        const [card, ...remainingDeck] = deck;
        const updatedCalledCards = [...calledCards, card];
        setDeck(remainingDeck);
        setCalledCards(updatedCalledCards);
        setNewCard(card);
        set(ref(database, 'calledCards'), updatedCalledCards);
    };

    const resetGame = () => {
        const newDeck = shuffleDeck(createDeck());
        setDeck(newDeck);
        setCalledCards([]);
        setNewCard(null);
        set(ref(database, 'calledCards'), []);
    };

    return (
        <div className="bingoCaller">
            <button onClick={callCard}>Call Card</button>
            <button onClick={resetGame}>Reset Game</button>
            {newCard && (
                <div>
                    <h2>New Card:</h2>
                    <img
                        src={newCard.image}
                        alt={`${newCard.value} of ${newCard.suit}`}
                        className="new-card" // Apply the CSS class
                    />
                </div>
            )}
        </div>
    );
};

export default BingoCaller;
