// src/deck.js

// Import card images
const cardImages = {
    '2_of_Clubs': require('../src/assets/cards/2_of_clubs.png'),
    '3_of_Clubs': require('../src/assets/cards/3_of_clubs.png'),
    '4_of_Clubs': require('../src/assets/cards/4_of_clubs.png'),
    '5_of_Clubs': require('../src/assets/cards/5_of_clubs.png'),
    '6_of_Clubs': require('../src/assets/cards/6_of_clubs.png'),
    '7_of_Clubs': require('../src/assets/cards/7_of_clubs.png'),
    '8_of_Clubs': require('../src/assets/cards/8_of_clubs.png'),
    '9_of_Clubs': require('../src/assets/cards/9_of_clubs.png'),
    '10_of_Clubs': require('../src/assets/cards/10_of_clubs.png'),
    'J_of_Clubs': require('../src/assets/cards/jack_of_clubs.png'),
    'Q_of_Clubs': require('../src/assets/cards/queen_of_clubs.png'),
    'K_of_Clubs': require('../src/assets/cards/king_of_clubs.png'),
    'A_of_Clubs': require('../src/assets/cards/ace_of_clubs.png'),
    '2_of_Diamonds': require('../src/assets/cards/2_of_diamonds.png'),
    '3_of_Diamonds': require('../src/assets/cards/3_of_diamonds.png'),
    '4_of_Diamonds': require('../src/assets/cards/4_of_diamonds.png'),
    '5_of_Diamonds': require('../src/assets/cards/5_of_diamonds.png'),
    '6_of_Diamonds': require('../src/assets/cards/6_of_diamonds.png'),
    '7_of_Diamonds': require('../src/assets/cards/7_of_diamonds.png'),
    '8_of_Diamonds': require('../src/assets/cards/8_of_diamonds.png'),
    '9_of_Diamonds': require('../src/assets/cards/9_of_diamonds.png'),
    '10_of_Diamonds': require('../src/assets/cards/10_of_diamonds.png'),
    'J_of_Diamonds': require('../src/assets/cards/jack_of_diamonds.png'),
    'Q_of_Diamonds': require('../src/assets/cards/queen_of_diamonds.png'),
    'K_of_Diamonds': require('../src/assets/cards/king_of_diamonds.png'),
    'A_of_Diamonds': require('../src/assets/cards/ace_of_diamonds.png'),
    '2_of_Hearts': require('../src/assets/cards/2_of_hearts.png'),
    '3_of_Hearts': require('../src/assets/cards/3_of_hearts.png'),
    '4_of_Hearts': require('../src/assets/cards/4_of_hearts.png'),
    '5_of_Hearts': require('../src/assets/cards/5_of_hearts.png'),
    '6_of_Hearts': require('../src/assets/cards/6_of_hearts.png'),
    '7_of_Hearts': require('../src/assets/cards/7_of_hearts.png'),
    '8_of_Hearts': require('../src/assets/cards/8_of_hearts.png'),
    '9_of_Hearts': require('../src/assets/cards/9_of_hearts.png'),
    '10_of_Hearts': require('../src/assets/cards/10_of_hearts.png'),
    'J_of_Hearts': require('../src/assets/cards/jack_of_hearts.png'),
    'Q_of_Hearts': require('../src/assets/cards/queen_of_hearts.png'),
    'K_of_Hearts': require('../src/assets/cards/king_of_hearts.png'),
    'A_of_Hearts': require('../src/assets/cards/ace_of_hearts.png'),
    '2_of_Spades': require('../src/assets/cards/2_of_spades.png'),
    '3_of_Spades': require('../src/assets/cards/3_of_spades.png'),
    '4_of_Spades': require('../src/assets/cards/4_of_spades.png'),
    '5_of_Spades': require('../src/assets/cards/5_of_spades.png'),
    '6_of_Spades': require('../src/assets/cards/6_of_spades.png'),
    '7_of_Spades': require('../src/assets/cards/7_of_spades.png'),
    '8_of_Spades': require('../src/assets/cards/8_of_spades.png'),
    '9_of_Spades': require('../src/assets/cards/9_of_spades.png'),
    '10_of_Spades': require('../src/assets/cards/10_of_spades.png'),
    'J_of_Spades': require('../src/assets/cards/jack_of_spades.png'),
    'Q_of_Spades': require('../src/assets/cards/queen_of_spades.png'),
    'K_of_Spades': require('../src/assets/cards/king_of_spades.png'),
    'A_of_Spades': require('../src/assets/cards/ace_of_spades.png'),
};

export const createDeck = () => {
    const suits = ['Clubs', 'Diamonds', 'Hearts', 'Spades'];
    const values = ['2', '3', '4', '5', '6', '7', '8', '9', '10', 'J', 'Q', 'K', 'A'];
    const deck = [];

    for (let suit of suits) {
        for (let value of values) {
            // Generate the card image path
            const imageName = `${value}_of_${suit}`;
            deck.push({
                value,
                suit,
                image: cardImages[imageName] // Use the imported images
            });
        }
    }

    return deck;
};

export const shuffleDeck = (deck) => {
    for (let i = deck.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [deck[i], deck[j]] = [deck[j], deck[i]];
    }
    return deck;
};
