import React, { useCallback, useEffect, useState } from 'react';
import { auth, firestore, doc, setDoc } from '../firebase.js';
import { onAuthStateChanged, setPersistence, browserLocalPersistence, signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth';
import ReCAPTCHA from 'react-google-recaptcha';
import './AccountPage.css';

function AccountPage() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [currentUser, setCurrentUser] = useState(null);
    const [showDropdown, setShowDropdown] = useState(false);
    const [showSplashScreen, setShowSplashScreen] = useState(false);
    const [redirectCountdown, setRedirectCountdown] = useState(5);
    const [recaptchaValue, setRecaptchaValue] = useState(null);
    const [loginError, setLoginError] = useState('');
    const recaptchaRef = useCallback(node => {
        if (node !== null) {
            // setRecaptchaValue(null); // Reset recaptcha value when the component mounts
        }
    }, []);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setCurrentUser(user);
            } else {
                setCurrentUser(null);
            }
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        let timer;
        if (showSplashScreen) {
            timer = setInterval(() => {
                setRedirectCountdown((prevCount) => prevCount - 1);
            }, 1000);

            setTimeout(() => {
                clearInterval(timer);
                setShowSplashScreen(false);
                window.location.href = '/'; // Redirect to home page
            }, 5000);
        }

        return () => clearInterval(timer);
    }, [showSplashScreen]);

    const onCaptchaChange = (value) => {
        setRecaptchaValue(value);
    };

    const handleLogin = () => {
        setLoginError(''); // Reset the error message
        setPersistence(auth, browserLocalPersistence)
        .then(() => {
            return signInWithEmailAndPassword(auth, email, password);
        })
        .then((userCredential) => {
            // User is signed in
            setShowSplashScreen(true);
            // You can handle the successful sign-in further here
        })
        .catch((error) => {
            if (error.code === 'auth/wrong-password') {
                setLoginError('Incorrect password. Please try again.');
            } else if (error.code === 'auth/user-not-found') {
                setLoginError('No account found with this email. Please register.');
            } else if (error.code === 'auth/too-many-requests') {
                setLoginError('Account temporarily locked due to too many login attempts. Please try again later.');
            } else {
                setLoginError('Login failed. Please try again.');
            }
        });
    };

    const handleRegister = () => {
        if (!recaptchaValue) {
            alert('Please verify that you are not a robot.');
            return;
        }

        setPersistence(auth, browserLocalPersistence)
        .then(() => {
            return createUserWithEmailAndPassword(auth, email, password);
        })
        .then((userCredential) => {
            // Assuming you have state or form fields for firstName and lastName
            const firstName = "UserFirstName"; // Replace with actual input
            const lastName = "UserLastName"; // Replace with actual input

            const userRef = doc(firestore, "Users", email); // Check collection name case

            return setDoc(userRef, {
                CheckIns: [],
                firstName: firstName,
                IsA: false,
                lastName: lastName
            });
        })
        .then(() => {
            alert('Registered successfully');
            window.location.href = "/profile";
        })
        .catch((error) => {
            console.error("Error in registration: ", error);
        });
    };

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    const handleSignOut = () => {
        auth.signOut().then(() => {
            // Sign-out successful.
            setCurrentUser(null);
        }).catch((error) => {
            // An error happened.
        });
    };

    if (!currentUser) {
        return (
            <div className="accountPage">
                <div className="loginContainer">
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Email"
                        className="fancyInput"
                    />
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Password"
                        className="fancyInput"
                    />
                    {loginError && <div className="errorMessage">{loginError}</div>}
                    <ReCAPTCHA
                        sitekey="6Ld1MEEpAAAAAMoY5L6QodYayYsfJuCPlFZZpnrU"
                        onChange={onCaptchaChange}
                    />
                    <button onClick={handleLogin} className="fancyButton">Login</button>
                    <button onClick={handleRegister} className="fancyButton">Register</button>
                </div>
            </div>
        );
    }

    if (showSplashScreen) {
        return (
            <div className="splashScreen">
                <div className="messageBox">
                    Welcome {currentUser ? currentUser.email : ''}! <br />
                    Redirecting in {redirectCountdown}...
                </div>
            </div>
        );
    }

    if (currentUser) {
        window.location.href ="/profile";
        return 
      }

    return (
        <div className="accountPage">
            <div className="header">
                {currentUser && (
                    <div className="userDetails" onClick={toggleDropdown}>
                        {currentUser.email}
                        {/* Hamburger menu icon */}
                        {showDropdown && (
                            <div className="dropdownMenu">
                                <a href="/profile">Profile</a> {/* Correct path */}
                                <a href="/settings">Settings</a>
                                <button onClick={handleSignOut}>Sign Out</button>
                            </div>
                        )}
                    </div>
                )}
                <h1>Account Page</h1>
            </div>
            {/* Other content for logged-in users */}
        </div>
    );
}

export default AccountPage;
