// src/components/BingoPage.js
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../AuthContext';
import BingoCaller from './BingoCaller';
import BingoBoard from './BingoBoard';
import { firestore } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';

const BingoPage = () => {
    const { currentUser } = useContext(AuthContext);
    const [isHost, setIsHost] = useState(false);

    useEffect(() => {
        const checkIsHost = async () => {
            if (currentUser) {
                const userRef = doc(firestore, 'users', currentUser.uid);
                const userSnap = await getDoc(userRef);
                if (userSnap.exists() && userSnap.data().isB) {
                    setIsHost(true);
                }
            }
        };

        checkIsHost();
    }, [currentUser]);

    return (
        <div>
            <h1>Bingo Game</h1>
            {isHost && <BingoCaller />}
            <BingoBoard />
        </div>
    );
};

export default BingoPage;
